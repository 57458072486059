body {
  color: #333;
}

.App {
  display: flex;
  flex-direction: column;
}

.main {
  width: 100%;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.border-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(43 43 45 / 92%);
  border: #47525d 2px solid;
  border-radius: 4px;
  color: #ffffff;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 500;
  height: 40px;
  cursor: pointer;
  transition: opacity 0.5s;
}

.border-button:hover {
  opacity: 0.5;
}

.icon {
  animation: pulse-color infinite alternate 2s ease-in-out;
}

.loading {
  animation: pulse infinite alternate 2s ease-in-out;
  font-size: 10vw;
  justify-content: center;
  align-items: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes pulse {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

@keyframes pulse-color {
  from {
    fill: rgb(78, 78, 78);
  }
  to {
    fill: rgb(41, 41, 43);
  }
}

.link {
  text-decoration: none;
  color: inherit;
  border-bottom: 1px solid #333;
}

.heading-6 {
  font-size: 1vw;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.heading-4 {
  margin-left: 0.5em;
  font-family: "Source Sans Pro", sans-serif;
  color: #777;
  font-size: 1.5vw;
  line-height: 1.5vw;
  font-weight: 600;
  letter-spacing: 0.2vw;
}

.heading-5 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 5vw;
}

.heading-4 {
  font-size: 2vw;
  line-height: 4vw;
}

.heading-3 {
  margin-top: 0vw;
  margin-bottom: 0px;
  margin-left: 2vw;
  font-family: "Source Sans Pro", sans-serif;
  color: #8f8f8f;
  font-size: 2vw;
  line-height: 2vw;
  font-weight: 600;
  letter-spacing: 0.4vw;
  text-transform: none;
  font-variant: all-small-caps;
}

.heading-1 {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 2vw;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 3.5vw;
  line-height: 3.5vw;
  font-weight: 400;
  letter-spacing: 0.8vw;
  font-variant: all-small-caps;
}

/* .text-block {
  text-align: center;
} */

.text-block {
  width: 100%;
  height: auto;
  min-height: 2vw;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

.text-align-right {
  text-align: right;
}

.text-1 {
  font-family: "Source Sans Pro", sans-serif;
  color: #000;
  font-size: 1.5vw;
  line-height: 2vw;
  font-weight: 300;
  width: auto;
}

.text-2 {
  font-family: "Source Sans Pro", sans-serif;
  color: #000;
  font-size: 1.4vw;
  line-height: 4vw;
  font-weight: 300;
  letter-spacing: 0.2vw;
  text-transform: uppercase;
}

.text-3 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.4vw;
  line-height: 2vw;
  font-weight: 300;
  letter-spacing: 0.3vw;
  text-transform: uppercase;
}

.loading-svg {
  height: 20vw;
  position: fixed;
  top: 40%;
  left: 34%;
}

.loader {
  border: 2px solid rgb(87, 184, 255);
  border-top: 2px solid rgb(47, 47, 47);
  border-radius: 50%;
  width: 6px;
  height: 6px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 767px) {
  .text-1 {
    width: auto;
    font-size: 2.8vw;
    line-height: 4vw;
    text-align: center;
  }
}

.MuiInputBase-root {
  color: white !important;
}

.MuiInputLabel-root {
  color: white !important;
}

.MuiInput-underline {
  border-bottom: 1px solid #232323 !important;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.MuiInput-underline:after {
  border-bottom: 1px solid white !important;
}

.MuiInput-underline:hover:not(.Mui-disabled) {
  border-bottom: 1px solid grey !important;
}

.MuiAutocomplete-input {
  color: white !important;
}

.MuiIconButton-root {
  color: rgb(255 255 255 / 54%) !important;
}

.MuiSelect-icon {
  color: rgb(255 255 255 / 54%) !important;
}

#settings .MuiInput-underline.Mui-error:after {
  border-bottom: 1px solid #f44336 !important;
}

.MuiTab-textColorSecondary {
  font-variant: all-small-caps !important;
  font-size: 18px !important;
  color: rgb(255 255 255 / 54%) !important;
}

.MuiTab-textColorSecondary.Mui-selected {
  color: #ffffff !important;
  font-weight: 600 !important;
}

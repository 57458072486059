tr {
  border-bottom: 1px solid #dddddd;
}

td {
  padding: 10px 10px;
  white-space: nowrap;
}

table .MuiInputBase-input {
  color: #232323;
}

#settings table .MuiInput-underline {
  border-bottom: 1px solid white !important;
}

#settings table .MuiInput-underline:after {
  border-bottom: 1px solid #232323 !important;
}

#settings table .MuiInput-underline:hover:not(.Mui-disabled) {
  border-bottom: 1px solid grey !important;
}

#settings table .MuiSelect-select:focus {
  background-color: white;
}

#settings .MuiChip-root {
  background-color: #ffffff !important;
  border: 1px solid #ddd;
}

#settings .expandable-row .MuiInputBase-root {
  cursor: pointer;
}

#settings .subrow {
  border-bottom: 1px solid white;
  background-color: rgb(244 244 244);
}

#settings .expandable-row {
  transition: all 0.5s;
  cursor: pointer;
}

#settings .expandable-row:hover {
  background-color: #f8f8f8;
}

#settings table .subrow .MuiInput-underline {
  border-bottom: 1px solid transparent !important;
}

.table-container {
  padding: 0px 14px 14px 14px;
  background-color: white;
  max-height: calc(100vh - 390px);
  overflow: auto;
}

.expand-arrow {
  font-size: 24px;
  cursor: pointer;
  transition: opacity 0.5s;
}

.expand-arrow:hover {
  opacity: 0.5;
}

.add-row {
  background-color: #232323;
  color: white;
  font-variant: all-small-caps;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: all 1s;
  opacity: 0.95;
}

.add-row.disabled {
  cursor: default;
}

.add-row:hover:not(.disabled) {
  opacity: 0.5;
}

th {
  color: #232323;
}

.table-action {
  font-size: 22px;
  cursor: pointer;
  transition: all 0.5s;
  margin-left: 10px;
}

.table-action.inactive {
  cursor: default;
  opacity: 0.5;
}

.table-action:hover:not(.inactive) {
  opacity: 0.5;
}

.confirm-cancel-button {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.5s;
}

.confirm-cancel-button:hover {
  opacity: 0.5;
}

table .confirm-cancel-button {
  color: #2c2c2c;
  margin-left: 10px;
}

.action-col {
  white-space: nowrap;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 10px;
}

.text-vertical {
  writing-mode: vertical-lr;
  text-orientation: mixed;
  transform: rotate(180deg);
  height: 100px;
  cursor: default;
  font-size: 12px;
  letter-spacing: 1px;
  width: 100%;
  display: flex;
  align-items: center;
}

.MuiChip-root.Mui-disabled {
  opacity: 1 !important;
}

.MuiChip-root.Mui-disabled .MuiChip-deleteIconSmall {
  display: none;
}

.MuiInputBase-root.Mui-disabled .MuiAutocomplete-endAdornment {
  display: none;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  padding: 20px 10px 30px 10px !important;
}

.tableFixHead thead .permissions th {
  top: 70px;
}

.tableFixHead.permissions-table th {
  box-shadow: inset 1px 1px rgb(221, 221, 221), 1px 1px rgb(221, 221, 221);
  border: none;
}

.tableFixHead thead tr {
  border: none;
}

.permissions-table tbody td {
  border-left: 1px solid rgb(221, 221, 221);
}

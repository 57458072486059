.text:hover {
  border:1px solid rgb(163, 163, 163);
}

.input {
  outline: none;
  border:1px solid rgb(163, 163, 163) !important;
  width:100%
}

.input-delete {
  margin-left: 10;
  color: grey;
  font-size: 1.5vw;
  cursor: pointer;
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.input-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.input-container {
  -ms-overflow-style: none;
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.ql-editor::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.ql-editor {
  -ms-overflow-style: none;
  scrollbar-width: none;  /* Firefox */
}

.editor-confirm-button {
  position: absolute;
  right: 12px;
  top: 7px;
  width: 32px;
  height: 24px;
}
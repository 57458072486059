.project-container-wrapper {
  display: flex;
  margin-top: 90px;
  background-color: #232323;
}

.project-container {
  width: 100%;
  height: 100%;
  height: calc(100vh - 160px);
  z-index: 10;
  background-color: #232323cf;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  padding-top: 70px;
  margin-left: 20px;
}

.footer-button {
  cursor: pointer;
  opacity: 1;
  transition: all 0.5s;
  position: relative;
  font-size: 2vh;
  margin-top: 6px;
}

.footer-button:hover {
  opacity: 0.5;
}

.published {
  font-size: 2vh;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 10px 10px;
}

.locked {
  font-size: 2vh;
  position: absolute;
  left: 0;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 10px 20px;
}

.project-content-container {
  width: 80vw;
  height: 60vw;
  min-height: 60vh;
  max-height: 70%;
  background-color: #eee;
  z-index: 20;
  position: relative;
}

.project-layout-grid {
  width: 100%;
  display: grid;
  grid-column-gap: 1%;
  grid-row-gap: 1vw;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.project-layout-container {
  color: grey;
  position: relative;
  transition: opacity 0.5s ease;
  opacity: 1;
  background-color: white;
  flex-direction: column;
  height: 250px;
  width: 300px;
  display: inline-grid;
  grid-row: 1fr 2fr;
  grid-auto-rows: 200px 50px;
  padding: 10px;
  margin: 10px;
}

.duplicate-arrow-down {
  font-size: 14px;
  width: 12px;
  height: 12px;
  position: absolute;
  right: -8px;
  background-color: white;
  border-radius: 14px;
  bottom: 3px;
}

.duplicate-arrow-pop-up {
  display: none;
  width: 150px;
  padding:10px;
  font-size: 14px;
  position: absolute;
  z-index: 99;
  top: 30px;
  left: -10px;
  border-radius: 10px;
  background-color: white;
  color: #232323;
  box-shadow: 0 0 5px 0px #2323238f;
}

.duplicate-arrow-pop-up.visible {
  display: block;
}

.duplicate-arrow-pop-up .option {
  font-variant: all-small-caps;
  padding: 4px;
  transition: all 1s;
  cursor: pointer;
}

.duplicate-arrow-pop-up .option:hover {
  color: rgb(197 197 197);
}

.all.project-layout-container {
  height: 290px;
  grid-auto-rows: 200px 40px 50px;
}

.project-owner-container {
  align-items: center;
  display: flex;
  padding-left: 10px;
  color: rgb(151 151 151);
  font-size: 14px;
}

.project-button {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #232323;
  border: #47525d 2px solid;
  border-radius: 4px;
  color: #ffffff;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 12px;
  height: 40%;
  max-height: 50px;
  cursor: pointer;
  transition: opacity 0.5s;
}

.project-title-container {
  height: 10%;
  background-color: #232323;
  display: flex; 
  align-items: center
}

.project-button:hover, .project-modal-button:active {
  opacity: 0.5;
}

.no-projects-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-item {
  cursor: pointer;
  background-color: #ccc;
  width: 100%;
  display: flex;
  justify-content: center; 
  align-items: center;
  opacity: 0.8;
  transition: opacity 0.5s;
}

.project-item:hover {
  opacity: 1;
}

.project-title {
  color: #ffffff;
  letter-spacing: 3px;
  font-weight: 500;
  font-size: 18px;
  margin-left: 20px;
}

.project-card-footer {
  background-color: white;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.project-grid-container {
  overflow-y: scroll; 
  height: 80%;
  margin: 2.5%
}

/* Hide scrollbar for Chrome, Safari and Opera */
.project-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.project-container {
  -ms-overflow-style: none;
  scrollbar-width: none;  /* Firefox */
}

.project-container-footer {
  height: 15%;
  display: flex;
  width: 100%;
  background-color: #eeeeee;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
}

.logout-container {
  color: white;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #535353;
  padding: 10px 30px;
  font-size: 18px;
  align-content: space-between;
  justify-content: space-between;
  top: 0vw;
  left: 3%;
}

.logout-icon {
  text-align: center;
  font-size: 25px;
  margin-left: 20px;
  cursor: pointer;
}

.people-container {
  color: white;
  background-color: #232323;
  /* margin: 10px 0px 0px 0px; */
  transition: all 1s;
  height: calc(100vh - 180px);
}

.people-container-inner {
  margin-left: 10px;
  padding-left: 10px; 
  height: inherit; 
  overflow-y: scroll;
  transition: all 1s;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.people-container-inner::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.people-container-inner {
  -ms-overflow-style: none;
  scrollbar-width: none;  /* Firefox */
}

.people-container-header {
  margin-bottom: 20px; 
  font-size: 20px;
  font-weight: 400; 
  background-color: #2c2c2c; 
  padding-left: 20px; 
  padding-right: 20px; 
  justify-content: space-between; 
  display: flex; 
  align-items: center; 
  height: 60px;
  cursor: pointer;
}

.number-of-projects {
  position: absolute;
  right: 10px;
  font-size: 12px;
  height: 24px;
  width: 24px;
  background-color: #343434;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.people-row {
  width: 220px;
  height: 40px; 
  display: flex; 
  align-items: center; 
  font-weight: 100;
  transition: background-color 0.5s;
  padding-left: 10px;
  cursor: pointer;
  margin-right: 70px;
  position: relative;
}

.people-row.reset {
  margin-bottom: 20px;
  background-color: rgb(45, 45, 45);
  color: white;
  font-variant: all-small-caps;
  justify-content: center;
  width: 100%;
  transition: all 0.5s;
}

.tot-projects {
  margin-left: 20px;
  background-color: #363636;
  padding: 4px 12px;
  border-radius: 15px;
}

.people-row.reset:hover {
  background-color: rgb(58 58 58);
}

.filter-tag {
  font-size: 14px;
  color: rgb(35 35 35);
  background-color: #eee;
  padding: 0px 10px 0px 10px;
  border-radius: 2px;
  font-weight: 700;
  cursor: pointer;
}

.people-row.register {
  margin-bottom: 20px;
  background-color: rgb(45, 45, 45);
  color: white;
  font-variant: all-small-caps;
}

.people-row:hover {
  background-color: #2d2d2d;
}

.people-row.register:hover {
  background-color: rgb(58 58 58);
}

.scroll-top-container {
  width: 75px;
  height: fit-content;
  color: white; 
  padding: 10px;
  transition: all 0.5s;
}

.scroll-top-container:hover {
  background-color: #2d2d2d;
}

.new-ad-button {
  height: 100px;
  width: 100px;
  position: fixed;
  bottom: 5vh;
  right: 10vw;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  background-color: white;
  color: #232323;
  box-shadow: 0 0 10px 0px #2323238f;
  z-index: 99;
  cursor: pointer;
  opacity: 1;
  transition: all .5s;
}

.new-ad-button.expanded {
  border-radius: 10px;
  width: 200px;
  flex-direction: column;
  align-items: start;
  padding: 20px;
  cursor: auto;
}

.new-ad-button .button {
  transition: all 0.5s;
  cursor: pointer;
  padding: 10px 10px;
  width: 100%;
  font-variant: all-small-caps;
  display: flex;
  align-items: center;
}

.new-ad-button .button:hover {
  color: rgb(197 197 197);
}

.template-label {
  position: absolute;
  top: 20px;
  left: 0px;
  width: 90px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  font-size: 12px;
  display: flex;
  justify-content: center;
  font-variant: all-small-caps;
  cursor: default;
}

.button-container {
  transition: all 0.3s ease 0.3s;
  font-size: 16px;
  opacity: 0;
}

.button-container.expanded {
  opacity: 1;
}

.new-ad-button:hover {
  opacity: 0.7;
}

.new-ad-button:hover {
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .project-layout-grid {
    grid-template-columns: 1fr 1fr;
  }

  .new-ad-button {
    height: 60px;
    width: 60px;
    font-size: 30px;
  }

  .project-button {
    min-width: 120px;
    min-height: 20px;
  }

  .project-container-footer {
    min-height: 50px;
  }

  .project-layout-container {
    height: 200px;
    width: 250px;
    grid-auto-rows: 150px 50px;
  }
}
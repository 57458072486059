.navbar {
  width: 100%;
  height:90px;
  min-width: 768px;
  position:fixed; 
  z-index: 5; 
  background-color:#232323; 
  color: white;
  display: flex;
  align-items: center;
}

.navbar-date-text {
  position: absolute;
  top: 66px;
  font-size: 11px;
}

.options-container {
  position: absolute;
  top: 35px;
  border-top: none !important;
  display: flex;
  align-items: center;
  width: 65px;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 0.5s ease;
  cursor: auto;
  background-color: #232323;
  flex-direction: column;
  justify-content: flex-end;
  right: 125px;
  top: 62px;
}

.options-container.visibility {
  width: 100px;
  right: 28px;
}

.navbar-more-icon {
  display: none;
  font-size: 40px;
  position: fixed;
  right: 5vw;
  cursor: pointer;
}

.navbar-options-icon {
  height: 10px;
  width: 10px;
  font-size: 10px;
  margin-left: 5px;
  color: white;
}

.menu-icon {
  font-size: 30px;
  padding-left: 30px;
  padding-right: 30px;
  cursor: pointer;
}

.navbar-text {
  font-size: 25px;
  font-family: 'Source Sans Pro';
  font-weight: 400;
}

.open-ad {
  font-size: 16px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-text2 {
  font-size: 25px;
  font-family: 'Source Sans Pro';
  margin-left: 5px;
  font-weight: 100;
  font-variant: all-small-caps;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.publishing {
  animation: spin infinite 5s linear;
  font-size: 20px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.navbar-button-text {
  font-variant: all-small-caps;
  margin-bottom: 3px;
  font-size: 14px;
}

.navbar-button-icon {
  font-size: 18px;
  margin-bottom: -3px;
}


.navbar-button-text.visibility {
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  text-align: center;
}

.publishing-container {
  position: fixed;
  right: 18px;
  height: 35px;
  display: flex;
  align-items: center;
  width: 122px;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid white;
  transition: all 0.5s ease;
  cursor: default;
  opacity: 0.5;
}

.navbar-buttons-container {
  margin-left: auto;
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-logout-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 5vw;
}

.navbar-buttons-container:focus {
  outline: none;
}

.navbar-button {
  height: 35px;
  display: flex;
  align-items: center;
  width: 65px;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  /* border: 1px solid white; */
  transition: all 0.5s ease;
  cursor: pointer;
  margin-left: 20px;
}

.navbar-button.settings {
  margin-left: 0px;
  border-right: 1px solid #eeeeee;
}

.navbar-button.visibility{
  width: 100px;
}

.navbar-button.disabled {
  opacity: 0.5;
  cursor: default;
}

.navbar-button:hover {
  background-color: #3f3f3f;
}

.navbar-back {
  width: 65px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  background-color: #2d2d2d;
  cursor: pointer;
  transition: all 0.5s;
}

.navbar-published-icon {
  width: 30px;
  height: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.navbar-back:hover {
  background-color: #333333;
}

.project-name {
  position: absolute;
  font-size: 15px;
  left: 4px;
  display: flex;
  color: #888;
  font-family: 'Source Sans Pro';
  font-weight: 400;
  letter-spacing: 1px;
  bottom: -18px;
  width: 400px;
}

.navbar-pipe {
  height: 30px;
  width: 1px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #ccc;
}

.publish-icon {
  font-size: 16px;
  position: absolute;
  right: 15px;
}

.toggle-base {
  position: relative;
  background-color: #414144;
  height: 25px;
  width: 50px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  color: white;
}

.publish-icon-line {
  font-size: 18px;
    position: absolute;
    /* right: 19px; */
    left: 33px;
    opacity: 0.6;
    background-color: hsla(230,9%,45%,1);
    width: 2px;
    height: 15px;
    transform: rotate(45deg);
}

.toggle-text {
  font-size: 12px;
  position: absolute;
  right: 15px;
}

.toggle-circle {
  background-color: white;
  position: absolute;
  height:15px;
  width:15px;
  border-radius: 15px;
  left: 5px;
  top: 5px;
  transition: all 0.2s;
}

@media screen and (max-width: 767px) {
  .navbar-more-icon {
    display: block;
  }

  .account-logo {
    display: none;
  }

  #user-name {
    display: none;
  }

  .navbar-button.settings {
    border-right: none
  }

  .navbar-logout-container {
    flex-direction: column;
    font-size: 12px;
  }

  .navbar-buttons-container {
    align-items: center;
    border: 1px solid white;
    justify-content: center;
    position: fixed;
    right: 5vw;
    margin-right: 0px;
    top: 30px;
    background-color: #232323;
    display: block;
  }

  .navbar-button {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 10px;
    padding-right: 10px;
    /* border: 1px solid white; */
    transition: all 0.5s ease;
    cursor: pointer;
    margin-left: 0px !important;
    background-color: #232323;
  }

  .navbar-date-text {
    display: none;
  }

  #navbar-options {
    display: none;
  }
  .options-container {
    border: 1px solid white;
    display: block;
    position: static;
    padding-right: 0px;
    width: 90px;
    overflow: hidden;
    border: none;
  }

  .navbar-button.visibility {
    width: 80px;
  }
}
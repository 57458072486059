.grid7 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167%;
  grid-template-columns: 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167%;
  -ms-grid-rows: 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw;
  grid-template-rows: 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw;
}

.grid7.preview {
  grid-template-rows: auto;
}

.grid7-container {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 12;
  grid-row-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 24;
  grid-column-end: 25;
  z-index: 0;
}

#grid7-text-container {
  position: relative;
  height: 100%;
  background-color: transparent;
  z-index: 1;
  margin-bottom: 0vw;
  background-color: hsla(0, 0%, 100%, 0.94);
  grid-column-end: 14;
  grid-column-start: 2;
  grid-row-end: 15;
  grid-row-start: 11;
}

#grid7-text-container-inner-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.04);
  background-color: transparent;
  opacity: 1;
}

#grid7-text-container-inner-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50%;
  padding-right: 2vw;
  padding-left: 2vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-style: none;
  border-width: 1px;
  border-color: #000;
  background-color: rgba(0, 0, 0, 0.04);
  opacity: 1;
}


@media screen and (max-width: 767px) {
  .grid7 {
    -ms-grid-rows: 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw;
    grid-template-rows: 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw;
  }

  #grid7-text-container {
    grid-column-end: 24;
    grid-column-start: 2;
    grid-row-end: 19;
    grid-row-start: 11;
  }

  #grid7-text-container-inner-1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

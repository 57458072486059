.add-button:hover, .add-button:active  {
  opacity: 0.4;
}

.icon-plus {
  font-size: 2vw;
  color: grey;
}

.iframe {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.background-video {
  position: relative;
  z-index: 1;
  height: 100%;
  overflow: hidden;
  color: white;
}


.background-video>video {
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  right: -100%;
  bottom: -100%;
  top: -100%;
  left: -100%;
  object-fit: cover;
  z-index: -100;


  /* For position editing:
    position: absolute;
    height: 115%; //Zoom
    bottom: 0%; //y-position
    left: -23%; //x-position */
}

video {
  display: inline-block;
  vertical-align: baseline;
}

.drop-container {
  position:absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.border {
  -webkit-box-shadow:inset 0px 0px 0px 0.5vw white;
  -moz-box-shadow:inset 0px 0px 0px 0.5vw white;
  box-shadow:inset 0px 0px 0px 0.5vw white;
}

#header-grid {
  display: grid;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167%;
  grid-template-columns: 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167%;
  -ms-grid-rows: 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw;
  grid-template-rows: 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw;
}

#header-title {
  -ms-grid-column-span: 12;
  grid-column-end: 24;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: center;
  align-self: center;
}

.cursor {
  cursor: pointer;
}



.header-image-1 {
  position: relative;
  z-index: 1;
  overflow: hidden;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 12;
  grid-row-end: 14;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 24;
  grid-column-end: 25;
}

.header-logo {
  -ms-grid-column-span: 4;
  grid-column-end: 24;
  -ms-grid-column: 20;
  grid-column-start: 20;
  -ms-grid-row-span: 4;
  grid-row-end: 6;
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-column-align: auto;
  justify-self: auto;
  z-index: 1;
  background-color: #e6e6e6;
}

.header-image-4 {
  -ms-grid-column-span: 10;
  grid-column-end: 25;
  -ms-grid-column: 15;
  grid-column-start: 15;
  -ms-grid-row-span: 6;
  grid-row-end: 20;
  -ms-grid-row: 14;
  grid-row-start: 14;
}

#header-image-grid {
  display: grid;
  margin-top: 1%;
  -ms-grid-column-span: 12;
  grid-column-end: 14;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 4;
  grid-row-end: 21;
  -ms-grid-row: 17;
  grid-row-start: 17;
}

#header-text-container {
  position: relative;
  height: 100%;
  background-color: transparent;
  z-index: 1;
  margin-bottom: 0vw;
  background-color: hsla(0, 0%, 100%, 0.94);
  -ms-grid-column-span: 12;
  grid-column-end: 14;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 5;
  grid-row-end: 17;
  -ms-grid-row: 12;
  grid-row-start: 12;
}

.header-image-2 {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

.header-image-3 {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#header-text-container-inner-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.04);
  background-color: transparent;
  opacity: 1;
}

.flexbox {
  display:flex;
}



#header-text-container-inner-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60%;
  padding-right: 2vw;
  padding-left: 2vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-style: none;
  border-width: 1px;
  border-color: #000;
  background-color: rgba(0, 0, 0, 0.04);
  opacity: 1;
}


@media screen and (max-width: 767px) {
  #header-grid {
    -ms-grid-rows: 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw;
    grid-template-rows: 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw;
  }

  #header-title {
    grid-row-end: 3;
  }

  .text-2 {
    font-size: 2vw;
    text-align: center;
    line-height: 5vw;
  }

  .heading-3 {
    font-size: 3vw;
    text-align: center;
  }

  .heading-1 {
    font-size: 4.5vw;
    line-height: 7vw;
    text-align: center;
  }


  .header-image-1 {
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 12;
    grid-row-end: 15;
  }

  .header-logo {
    -ms-grid-row: 4;
    grid-row-start: 4;
    -ms-grid-column-span: 4;
    grid-column-end: 24;
    -ms-grid-column: 20;
    grid-column-start: 20;
    -ms-grid-row-span: 4;
    grid-row-end: 7;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }

  .header-image-4 {
    -ms-grid-column: 5;
    grid-column-start: 5;
    -ms-grid-column-span: 16;
    grid-column-end: 21;
    -ms-grid-row: 30;
    grid-row-start: 30;
    -ms-grid-row-span: 10;
    grid-row-end: 40;
  }

  #header-image-grid {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 22;
    grid-column-end: 24;
    -ms-grid-row: 21;
    grid-row-start: 21;
    -ms-grid-row-span: 8;
    grid-row-end: 29;
  }

  #header-text-container {
    -ms-grid-column-span: 22;
    grid-column-end: 24;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 8;
    grid-row-end: 21;
    -ms-grid-row: 13;
    grid-row-start: 13;
  }

  #header-text-container-inner-1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .flexbox {
    justify-content: center;
  }

  

}

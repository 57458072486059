.confirm-bg {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 21;
  height: 100%;
  width: 100%;
  background-color: rgba(41, 41, 43, 0.81);
}

.confirm-container {
  z-index: 22;
  position: fixed;
  height: 30%;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  top: 35%;
  left: 25%;
  display: flex;
  background-color: rgb(238, 238, 238);
}

.confirm-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 15px;
  background-color: rgb(43 43 45 / 92%);
  color: white;
  font-variant: all-small-caps;
  letter-spacing: 1px;
}

.confirm-message {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  padding: 30px;
}

.confirm-button-container {
  display: flex;
  width: 50%;
  font-variant: all-small-caps;
  padding-bottom: 20px;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .confirm-container {
    width: 400px;
    left: calc(50% - 200px);
  }
}
.prev,
.next {
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6vh;
  height: 6vh;
  margin-left: 1vw;
  margin-right: 1vw;
  border-radius: 6vh;
  color: white;
  font-weight: bold;
  font-size: 3vh;
  transition: 0.6s ease;
  user-select: none;
  -webkit-user-select: none;
  z-index: 2;
  top: calc(58% - 5vw);
}

.next {
  right: 20px;
}

.prev {
  left: 20px;
}

.slider-control {
  height: 5vw;
  width: 5vw;
  font-size: 3vw;
}

@media screen and (max-width: 767px) {
  .slider-control {
    font-size: 20px;
    width: 40px;
    height: 40px;
  }
  .prev,
  .next {
    top: calc(58% - 40px);
  }
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.slider .slide {
  height: 100%;
  display: flex;
  cursor: pointer;
  background-size: cover;
}
.slider .slide img {
  height: 100%;
}

.slider {
  position:relative;
  overflow: hidden;
  width: 100%; 
  height: 100%;
}

.prev-slider,
.next-slider {
  cursor: pointer;
  position: absolute;
  z-index: 4;
  bottom: 20%;
  width: auto;
  padding: 32px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 40px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

.next-slider {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev-slider:hover,
.next-slider:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.text:hover {
  border:1px solid rgb(163, 163, 163);
}

.input {
  outline: none;
  border:1px solid rgb(163, 163, 163) !important;
  width:100%
}

.input-delete {
  margin-left: 10;
  color: grey;
  font-size: 1.5vw;
  cursor: pointer;
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
}
.map-input {
  position: absolute;
  top: 5%;
  right: 12.5%;
  width: 70%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  padding: 2%;
  z-index: 2;
}

.section {
  width:100%;
  margin-bottom: 3vw;
}

.title-grid {
  display: -ms-grid;
  display: grid;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167%;
  grid-template-columns: 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167%;
  -ms-grid-rows: 4.167vw 4.167vw 4.167vw 4.167vw;
  grid-template-rows: 4.167vw 4.167vw 4.167vw 4.167vw;
}

.tag {
  -ms-grid-column-span: 10;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  display: flex;
  align-items: center;
}

.title {
  -ms-grid-column-span: 22;
  grid-column-end: 24;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 3;
  grid-row-end: 5;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
  width: 100%;
}

.title-grid.tag-only {
  grid-template-rows: 4.167vw;
}

.title-grid.title-only {
  grid-template-rows: 4.167vw;
  margin-bottom: 3vw;
  margin-top: 3vw;
}

.title-grid.title-only .title {
  grid-row-start: 1;
}

.heading-4 {
  margin-top: 0vw;
  margin-bottom: 0px;
  margin-left: 2vw;
  font-family: 'Source Sans Pro', sans-serif;
  color: #8f8f8f;
  font-size: 1.5vw;
  line-height: 1.5vw;
  font-weight: 600;
  letter-spacing: 0.4vw;
  text-transform: none;
}

.heading-2 {
  position: static;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #9e9e9e;
  font-size: 7vw;
  line-height: 6vw;
  font-weight: 200;
  text-align: center;
  letter-spacing: 0.4vw;
}
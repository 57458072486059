.add-button {
  width: 91.666%;
  margin-left: 4.167%;
  height: 5vw;
  display: flex;
  min-height: 40px;
  align-items: center;
  justify-content: center;
  background-color: #3d3d3d;
  /* border-radius: 1vw; */
  /* border: dashed 2px black; */
  cursor:pointer;
  opacity: 1;
  transition: opacity 0.2s ease;
  color: white;
  margin-top: 2vh;
}

.add-button:hover, .add-button:active  {
  opacity: 0.9;
}

.icon-plus {
  font-size: 2vw;
  font-variant: small-caps;
  color: white;
  display: flex;
  align-items: center;
}
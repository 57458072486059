.react-transform-component {
  overflow: visible !important;
}

.settings-container {
  max-width: 100%;
  overflow-y: hidden;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 4;
  background-color: #3b3b3bb3;
  color: white;
  /* transition: width 0.8s ease; */
}

.slideshow-settings {
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 4;
  color: #3b3b3bb3;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transition: width 0.8s ease; */
}

.settings-icon {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0vw 15px;
  cursor: pointer;
  background-color: #6d6d6d00;
  transition: all 0.8s ease;
}

.settings-icon.expanded {
  background-color: #b1b1b1;
}

.settings-text {
  width: 340px;
  height: 50%;
  background-color: #3b3b3bd9;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.speed-text {
  font-size: 0.7rem;
  margin-left: -0.3rem;
  text-align: center;
  margin-top: 1.6rem;
  background-color: white;
  border-radius: 1rem;
  width: 1.2rem;
  height: 1.1rem;
  color: #3b3b3b;
  font-weight: 700;
}

.img-container {
  height: 100%;
  width: 100%;
}

.transform-img {
  display: block;
  object-fit: contain;
  height: 100%;
  width: 100%
}

.react-transform-component {
  width: 100% !important;
  height: 100% !important;
}

.react-transform-element {
  width: 100% !important;
  height: 100% !important;
}
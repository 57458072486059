.iframe-input-inner {
  position: absolute;
  top: 5%;
  left: 12.5%;
  width: 70%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  padding: 2%;
}

.iframe-error {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d89494;
  color: white;
  height: 39px;
  bottom: 0;
  position: absolute;
}
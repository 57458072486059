.disabled {
  opacity: 0.4;
}

#structure-list-section {
  margin: 1vw;
  padding: 0.5vw;
  background-color: #ffffff45;
  color: white;
  position: relative;
}

#structure-list-grid {
  padding: 0.7vw;
  position: relative;
  height: 60px;
  margin: 0vw 1vw;
}

#draggable-list-container {
  height: calc(100vh - 230px);
  overflow-y: scroll;
  padding-bottom: 2vw;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#draggable-list-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
#draggable-list-container {
  -ms-overflow-style: none;
  scrollbar-width: none;  /* Firefox */
}

.modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  background-color: #232323cf;
}

.layout-modal-title {
  color: white;
  font-size: 1.5vw;
  letter-spacing: 4px;
}

.modal-content-container {
  width: 90vw;
  height: 60vw;
  min-height: 300px;
  max-height: 70%;
  display: flex;
  background-color: white;
  z-index: 20;
  flex-direction: column;
  position: relative;
}

.modal-arrow {
  position: absolute;
  top: calc(50% - 2vw);
  width: 10vw;
  font-size: 6vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #232323;
  cursor: pointer;
  transition: all 0.2s;
}

.modal-arrow.left {
  left: 0px;
}

.modal-arrow.right {
  right: 0px;
}

.modal-arrow:hover {
  color: #eeeeee;
}

.layout-grid {
  width: 80%;
  margin-left: 10%;
  display: grid;
  grid-column-gap: 1%;
  grid-row-gap: 1vw;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  height: 70%;
}

.template-layout-grid {
  width: 80%;
  margin-left: 10%;
  display: grid;
  grid-column-gap: 5%;
  grid-row-gap: 1vw;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  height: 75%;
}

.template-iframe {
  border: 1px solid #ccc;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.template-button {
  margin-top: 20px;
  padding: 15px 25px;
  background-color: #232323;
  color: white;
  text-align: center;
  font-size: 1vw;
  opacity: 1;
  transition: opacity 0.5s ease;
}

.layout-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color:grey;
  cursor: pointer;
  transition: opacity 0.5s ease;
  opacity: 1;
}

.template-layout-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color:grey;
  cursor: pointer;
  transition: opacity 0.5s ease;
  opacity: 1;
  flex-direction: column;
}

.template-button:hover, .template-button:active {
  opacity: 0.5;
}

.layout-container:hover, .layout-container:active {
  opacity: 0.5;
}

.divider {
  height:5vh;
  width:100%;
}
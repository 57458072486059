/* Prevent "overscrolling" on mobile browsers */
body {
  height: 100%;
  /* overflow: hidden; */
  max-height: 100%;
}
html {
  /* overflow: hidden; */
  height: 100%;
  max-height: 100%;
}

#root {
  /* Setting root container to same dimensions as viewport (important for mobile browsers) */
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
  max-height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.noselect > * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* Disables double-tap to zoom and other non-standard interactions */
* {
  touch-action: manipulation;
}

.MuiPopover-root {
  z-index: 9999999 !important;
}

.video.MuiInput-underline {
  border-bottom: 1px solid white !important;
}

.video.MuiInput-underline:hover:not(.Mui-disabled) {
  border-bottom: 1px solid white !important;
}

.video.MuiInput-root {
  margin-top: 20px !important;
}

.video.MuiInputLabel-root {
  font-size: 12px !important;
  font-weight: 700 !important;
  letter-spacing: 0.5px !important;
}

.video .MuiSelect-root {
  font-size: 12px !important;
}

.video .MuiListItem-root {
  font-size: 12px !important;
}

.animated-loading-background {
  animation: spin 6s linear infinite;
  background: linear-gradient(
    360deg,
    #ffffff,
    #ffffff,
    #4e4e4e,
    #2f2f2f,
    #2f2f2f
  );
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

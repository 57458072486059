.item {
  width: 100%;
  height: 40px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid white; */
  /* border-radius: 4px; */
  font-size: 1.4rem;
  cursor: pointer;
  transition: all 0.5s ease;
  color: white;
  text-align: center;
}

.drawer-container {
  height: 100%;
  width: 200px;
  position: fixed;
  top: 90px;
  display: flex;
  flex-direction: column;
  background-color: #2d2d2d;
  z-index: 10;
  display: flex;
  /* transition: all 1s ease; */
  /* min-height: 100vh; */
  height: 92vh;
  margin-right: 1vw;
  /* border-right: 2px solid #ffffff; */
}

.delete-upload {
  cursor: pointer;
  font-size: 16px;
  left: 40px;
  display: none;
  opacity: 1;
  transition: all 0.5s;
  bottom: 16px;
  position: absolute;
  background-color: white;
  padding: 0px 7px 0px 7px;
}

.times-used {
  font-size: 16px;
  right: 29px;
  border-radius: 20px;
  display: none;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  bottom: 5px;
  position: absolute;
  background-color: #ffffff;
}

.drawer-icons {
  display: flex;
}

.item:hover, .item:active {
  color: rgb(182, 182, 182);
  cursor: pointer;
}

.drawer-content-inner-container {
  opacity: 0;
  margin-top: 1vh;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  text-align: center;
  font-size: 12px;
  height: calc(100% - 90px);
  position: relative;
}

.img {
  height: 80px;
  margin:10px;
  border: 3px solid white;
  cursor: grab;
  max-width: 170px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.drawer-content-inner-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.drawer-content-inner-container {
  -ms-overflow-style: none;
  scrollbar-width: none;  /* Firefox */
}

.drawer-content-inner-container > * {
  outline: 0;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.video-loading {
  animation: spin infinite 5s linear;
  position: initial;
  font-size: 4vw;
  color:white;
  border: none !important;
  display: block;
}


.drawer-other {
  background-color: #4f4f4f;
  border: 1px solid;
  color: white;
  width: 60%;
  margin-left: 15%;
  padding: 5%;
  word-break: break-all;
  margin-bottom: 1vw;
  cursor: grab;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  font-feature-settings: "smcp", "c2sc";
  font-variant: all-small-caps;
  font-family: 'Source Sans Pro';
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.footer-grid {
  display: -ms-grid;
  display: grid;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-columns: 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167%;
  grid-template-rows: 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw;
}

#footer-info-container {
  -ms-grid-column-span: 22;
  grid-column-end: 24;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 5;
  grid-row-end: 7;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#footer-info-grid {
  display: -ms-grid;
  display: grid;
  height: 100%;
  grid-column-gap: 0vw;
  grid-row-gap: 1vw;
  -ms-grid-columns: 3.5fr 1fr;
  grid-template-columns: 3.5fr 1fr;
  -ms-grid-rows: 1fr 0.7fr;
  grid-template-rows: 1fr 0.7fr;
}

.footer-broker-img {
  height: 25vw;
  padding: 0px !important;
  transform: translateY(-0.5vw);
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.frame {
  padding: 1.6em 0px 0.5em 0em;
  border: 0.5vw solid #fff;
}

#footer-broker-info {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#footer-broker-info-2 {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  height: 100%;
  margin-left: 2vw;
}

#footer-more-info {
    -ms-grid-column-span: 6;
    grid-column-end: 8;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 2;
    grid-row-end: 10;
    -ms-grid-row: 8;
    grid-row-start: 8;
}

.link-box:hover, .link-box:active {
  background-color: #333;
  transition: all 0.3s linear;
  color:white;
}

.link-box {
  background-color: white;
  cursor: pointer;
  border: 1px solid #000;
}

#footer-contact-us {
  -ms-grid-column-span: 6;
  grid-column-end: 8;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 2;
  grid-row-end: 13;
  -ms-grid-row: 11;
  grid-row-start: 11;
}

#footer-fb {
  -ms-grid-column-span: 2;
  grid-column-end: 13;
  -ms-grid-column: 11;
  grid-column-start: 11;
  -ms-grid-row-span: 2;
  grid-row-end: 13;
  -ms-grid-row: 11;
  grid-row-start: 11;
}

#footer-email {
  -ms-grid-column-span: 2;
  grid-column-end: 18;
  -ms-grid-column: 16;
  grid-column-start: 16;
  -ms-grid-row-span: 2;
  grid-row-end: 13;
  -ms-grid-row: 11;
  grid-row-start: 11;
}

#footer-instagram {
  -ms-grid-column-span: 2;
  grid-column-end: 23;
  -ms-grid-column: 21;
  grid-column-start: 21;
  -ms-grid-row-span: 2;
  grid-row-end: 13;
  -ms-grid-row: 11;
  grid-row-start: 11;
}

#footer-link-top {
  grid-column-end: 24;
  grid-column-start: 20;
  grid-row-end: 11;
  grid-row-start: 8;
}

.link {
  text-decoration: none;
  color: inherit;
  border-bottom: 1px solid #333;
}

.footer-social-grid {
  height: 50%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.footer-social-grid {
  opacity: 1;
}

.footer-social-grid:hover {
  transition: all 0.3s linear;
  opacity: 0.5;
}

.footer-social-icons {
  font-size: 3vw;
  width: 2.5vw !important;
  height: 2.5vw !important;
  margin: 0px !important;
}

#footer-broker-info-2-inner {
  display: grid;
  height: 100%;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.flexbox {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-decoration {
  text-decoration: none; 
  color: inherit;
}

#footer-broker-mail {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  justify-content: left;
}

#footer-contact-button {
  grid-column-end: 7;
  grid-column-start: 2;
  grid-row-end: 9;
  grid-row-start: 8;
  margin-left: 2vw;
}

#footer-broker-tel {
  justify-content: left;
}

.mail {
  width: 2vw;
  height: 2vw;
  margin-right: 10px;
  background-color: initial;
}

.tel {
  width: 2vw;
  height: 2vw;
  margin-right: 10px;
  background-color: initial;
}

.no-margin {
  margin: 0;
}

@media screen and (max-width: 767px) {
  .footer-grid {
      grid-template-rows: 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw;
  }

  #footer-info-container {
    -ms-grid-column-span: 22;
    grid-column-end: 24;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 8;
    grid-row-end: 10;
    -ms-grid-row: 2;
    grid-row-start: 2;
}

#footer-contact-button {
  -ms-grid-row: 11;
  grid-row-start: 11;
  -ms-grid-row-span: 2;
  grid-row-end: 13;
}

#footer-info-grid {
    -ms-grid-columns: 2.25fr 1fr;
    grid-template-columns: 2.25fr 1fr;
    -ms-grid-rows: 0.5fr 0.5fr;
    grid-template-rows: 0.5fr 0.5fr;
}

.footer-broker-img {
  height: 35vw;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
}


#footer-broker-info-2-inner {
  grid-row-gap: 0px;
  -ms-grid-rows: minmax(33%, 33%) minmax(33%, 33%) minmax(33%, 33%) minmax(33%, 33%);
  grid-template-rows: minmax(33%, 33%) minmax(33%, 33%) minmax(33%, 33%) minmax(33%, 33%);
}

.heading-1-special {
  font-size: 4vw;
  line-height: 5vw;
  text-align: left;
}

#footer-broker-mail .text-1 {
  text-align: left !important;
}

#footer-broker-tel .text-1 {
  text-align: left !important;
}

#footer-more-info {
    -ms-grid-row: 11;
    grid-row-start: 11;
    -ms-grid-row-span: 2;
    grid-row-end: 13;
}

#footer-contact-us {
  -ms-grid-row: 14;
  grid-row-start: 14;
  -ms-grid-row-span: 2;
  grid-row-end: 16;
}

#footer-fb {
  -ms-grid-row: 13;
  grid-row-start: 13;
  -ms-grid-row-span: 3;
  grid-row-end: 16;
  -ms-grid-column-span: 3;
  grid-column-end: 13;
  -ms-grid-column: 10;
  grid-column-start: 10;
}

#footer-instagram {
  -ms-grid-row: 13;
  grid-row-start: 13;
  -ms-grid-row-span: 3;
  grid-row-end: 16;
  -ms-grid-column-span: 3;
  grid-column-end: 23;
  -ms-grid-column: 20;
  grid-column-start: 20;
}

#footer-email {
  -ms-grid-row: 13;
  grid-row-start: 13;
  -ms-grid-row-span: 3;
  grid-row-end: 16;
  -ms-grid-column-span: 3;
  grid-column-end: 18;
  -ms-grid-column: 15;
  grid-column-start: 15;
}

.heading-6 {
  font-size: 1.5vw;
}

#footer-link-top {
  grid-row-start: 12;
  grid-row-end: 12;
  grid-column-end: 16;
  grid-column-start: 10;
}

.footer-social-icons {
  width: 5vw;
  height: 5vw;
  font-size: 5vw
}

.heading-4 {
  font-size: 2vw;
  line-height: 2.5vw;
}

.text-3 {
  font-size: 1.8vw;
}

}
#settings-edit-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0px 30px;
  background-color: #2c2c2c;
  height: fit-content;
  padding: 40px 0px;
}

#division-name {
  font-size: 24px !important;
}

#account-name {
  font-size: 24px !important;
}

#account-combo {
  font-size: 24px !important;
}

#account-filter-combo {
  font-size: 18px !important;
}

#division-filter-combo {
  font-size: 18px !important;
}

#user-filter-combo {
  font-size: 18px !important;
}

#logo-url {
  font-size: 24px !important;
}

table .MuiAutocomplete-inputRoot {
  margin: 0;
}

table .MuiIconButton-root {
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiChip-root {
  background-color: #eee !important;
  padding: 5px !important;
  margin: 4px !important;
}

table .MuiAutocomplete-input {
  color: #232323 !important;
}

.settings-container-wrapper {
  display: flex;
  margin-top: 90px;
  background-color: #232323;
  height: calc(100vh - 90px);
}

.table-label {
  font-size: 20px;
  margin: 20px 0px;
  color: white;
  font-weight: 600;
  font-variant: all-small-caps;
  width: 50px;
  border-radius: 10px;
}

.input-division-name {
  margin-top: 6px;
  margin-bottom: 40px;
  font-size: 36px;
  color: white;
  background-color: #232323;
  outline: none;
  /* border-bottom: 1px solid; */
  border: none;
}

.input-division-name:focus {
  border-bottom: 1px solid grey;
}

.create {
  margin-bottom: 10px;
  max-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #eeeeee;
  font-variant: all-small-caps;
  color: rgb(35, 35, 35);
  transition: opacity 0.5s;
  cursor: pointer;
}

.create:hover {
  opacity: 0.5;
}

.trash {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: white; 
  font-size: 28px;
  transition: opacity 0.5s;
}

.trash:hover {
  opacity: 0.5;
}

.add-button-circle {
  position: absolute;
  right: 10px;
  font-size: 16px;
  padding: 5px;
  background-color: #343434;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.5s;
}

.add-button-circle:hover {
  opacity: 0.5 !important;
}


.grid {
  display: grid;
  margin-bottom: 1vw;
}

.preview {
  width: 100%;
  height: 100%;
  grid-row-gap: 1% !important;
  margin-bottom: 0px;
}

.grid1 {
  grid-column-gap: 1%;
  grid-row-gap: 1vw;
  grid-template-columns: 15.834% 15.834% 15.834% 15.834% 15.834% 15.834%;
  grid-template-rows: 15.834vw 15.834vw;
}

.grid1.preview {
  grid-template-rows: 1fr 1fr !important;
  -ms-grid-rows: 1fr 1fr !important;
}

.grid1-1 {
  grid-column-end: 4;
  grid-column-start: 1;
  grid-row-end: 3;
  grid-row-start: 1;
  background-color: #ffe0e0;
}

.grid1-2 {
  grid-column-end: 7;
  grid-column-start: 4;
  grid-row-end: 3;
  grid-row-start: 1;
  background-color: #9fa3a4;
}

.bg-img {
  position: relative;
  z-index: 2;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-color: #eee;
}

.grid2 {
  grid-column-gap: 1%;
  grid-row-gap: 1vw;
  grid-template-columns: 10.223% 10.223% 10.223% 10.223% 10.223% 10.223% 10.223% 10.223% 10.223%;
  grid-template-rows: 10.223vw 10.223vw 10.223vw 10.223vw;
}

.grid2.preview {
  grid-template-rows: 1fr 1fr 1fr 1fr !important;
}

.grid2-1 {
  grid-row-start: span 2;
  grid-row-end: span 2;
  grid-column-start: span 3;
  grid-column-end: span 3;
}

.grid2-2 {
  grid-column-end: 4;
  grid-column-start: 1;
  grid-row-end: 5;
  grid-row-start: 3;
}

.grid2-3 {
  grid-row-start: 1;
  grid-row-end: 5;
  grid-column-start: 4;
  grid-column-end: 10;
}

.grid3 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50vw;
}

.grid3.preview {
  grid-template-rows: 1fr !important;
}

.grid3-1 {
  grid-column-end: 3;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-row-start: 1;
}

.grid4-1 {
  grid-row-start: 1;
  grid-row-end: 5;
  grid-column-start: 1;
  grid-column-end: 7;
}

.grid4-2 {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 7;
  grid-column-end: 10;
}

.grid4-3 {
  grid-column-end: 10;
  grid-column-start: 7;
  grid-row-end: 5;
  grid-row-start: 3;
}

.grid5 {
  grid-row-gap: 0px;
  grid-template-columns: 7.616% 7.616% 7.616% 7.616% 7.616% 7.616% 7.616% 7.616% 7.616% 7.616% 7.616% 7.616% 7.616%;
  grid-template-rows: 7.616vw 7.616vw 7.616vw 7.616vw 7.616vw 7.616vw;
}

.grid5.preview {
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr !important;
}

.grid5-1 {
  grid-row-start: span 6;
  grid-row-end: span 6;
  grid-column-start: span 9;
  grid-column-end: span 9;
}

.grid5-2 {
  grid-column-end: 14;
  grid-column-start: 10;
  grid-row-end: 7;
  grid-row-start: 1;
  margin-left: 1vw;
}

.preview .grid5-2 {
  margin-left: 1%;
}

.grid8 {
  grid-row-gap: 0px;
  grid-template-columns: 7.616% 7.616% 7.616% 7.616% 7.616% 7.616% 7.616% 7.616% 7.616% 7.616% 7.616% 7.616% 7.616%;
  grid-template-rows: 7.616vw 7.616vw 7.616vw 7.616vw 7.616vw 7.616vw;
}

.grid8.preview {
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr !important;
}

.grid8-1 {
  grid-row-end: 7;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-column-end: 5;
}

.grid8-2 {
  grid-column-end: 5;
  grid-column-start: 14;
  grid-row-end: 7;
  grid-row-start: 1;
  margin-left: 1vw;
}

.preview .grid8-2 {
  margin-left: 1%;
}

.grid6 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-columns: 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167%;
  grid-template-rows: 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw;
}

.grid6.preview {
  grid-template-rows: auto;
}

.grid6-1 {
  grid-column-end: 11;
  grid-column-start: 5;
  grid-row-end: 11;
  grid-row-start: 2;
}

.grid6-2 {
  grid-column-end: 21;
  grid-column-start: 15;
  grid-row-end: 11;
  grid-row-start: 2;
}

#grid9-text-container-inner-1 {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
  opacity: 1;
}

#grid9-text-container {
  position: relative;
  height: 100%;
  background-color: transparent;
  z-index: 1;
  margin-bottom: 0vw;
  background-color: hsla(0, 0%, 100%, 0.94);
  grid-column-end: 24;
  grid-column-start: 15;
  grid-row-end: 14;
  grid-row-start: 8;
}

.grid9-container {
  grid-row-start: 1;
  -ms-grid-row-span: 12;
  grid-row-end: 15;
  grid-column-start: 1;
  -ms-grid-column-span: 24;
  grid-column-end: 25;
  z-index: 0;
}

.grid9.preview {
  grid-template-rows: auto;
}

.grid9 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167%;
  grid-template-columns: 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167% 4.167%;
  -ms-grid-rows: 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw;
  grid-template-rows: 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw 4.167vw;
}

.grid9-small-container {
  background-color: #e6e6e6;
}
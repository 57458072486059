.snackbar {
  background-color: white;
  box-shadow: 0 0 0px 15px rgb(255 255 255 / 0.85);
  width: 30%;
  height: 50px;
  position: fixed;
  border-radius: 40px;
  bottom: -50px;
  left: calc(50% - 15%);
  display: flex;
  justify-content: center;
  transition: all 0.5s;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .snackbar {
    width: 50%;
    left: 25%;
  }
}
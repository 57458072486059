.MuiFormControl-root {
  width:100%
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color:#232323 !important;
}

.login-container .MuiFormLabel-root.Mui-focused {
  color: #232323 !important;
}

.login-container .MuiFormLabel-root {
  color: #232323 !important;
}

.login-container .MuiInputBase-root {
  color: #232323 !important;
}

.login-text {
  font-size: 35px;
  font-family: 'Source Sans Pro';
  font-weight: 400;
}

.login-text2 {
  font-family: 'Source Sans Pro';
  margin-left: 10px;
  align-self: flex-end;
  font-weight: 100;
  font-variant: all-small-caps;
  font-size: 24px;
  height: 24px;
  margin-bottom: 1px;
}

.login-container {
  height: calc(100% - 10vh);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
  transition: background-color 1s;
}

.login-content-container {
  width: 600px;
  height: 0px;
  background-color: white;
  overflow: hidden;
  z-index: 20;
  position: relative;
  transition: all 1s;
  max-height: 70%;
  min-width: 600px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.login-nav {
  height: 100px;
  display: flex;
  position: absolute;
  align-items: center;
  width: 100%;
}

.login-logo-text {
  fill: hsl(0 0% 85% / 1);
  margin-left: 6vw;
}

.login-logo-container {
  animation: pan infinite alternate 20s ease-in-out;
  height: 40vw;
  width: 40vw;
  min-width: 240px;
  min-height: 240px;
}

.login-text-container {
  display: flex;
  color: #eeeeee; 
  margin-left: 20px;
  margin-bottom: 10px;
}

.login-modal-button:active {
  opacity: 0.5;
}

.login-modal-title {
  margin-top: 0vw;
  margin-bottom: 0px;
  margin-left: 2vw;
  font-family: 'Source Sans Pro', sans-serif;
  color: #8f8f8f;
  font-size: 1.5vw;
  line-height: 1.5vw;
  font-weight: 300;
  letter-spacing: 3px;
  text-transform: none;
}

@keyframes pan {
  0% {
    background-position-x: center;
    background-position-y: center;
    background-size: 90%;
  }
  30% {
    background-position-x: left;
    background-size: 150%;
  }
  70% {
    background-position-x: right;
    background-size: 150%;
  }
  100% {
    background-position-x: center;
    background-position-y: center;
    background-size: 90%;
  }
}

.login-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #232323;
  border-radius: 4px;
  color: #232323;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 700;
  height: 40px;
  transition: all 0.5s;
  z-index: 2;
  opacity: 0.5;
  cursor: default;
}

.login-button.active {
  opacity: 1;
  cursor: pointer;
  background-color: #232323;
  color: white;
}

.login-button.active:hover {
  opacity: 0.8;
}

.login-icon-container {
  z-index: 1;
  width: 52%;
  height: 52vw;
}

.login-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 30%;
  width: 80%;
  max-width: 700px;
  min-width: 200px;
  margin-left: 10px;
  bottom: 70px;
  z-index: 2;
  margin-top: -4vw;
}

.recovery-link {
  cursor: pointer;
  transition: opacity 0.5s;
}

.recovery-link:hover {
  opacity: 0.5;
}
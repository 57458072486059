  
.expand-grid-container {
  width: 91.666%;
  margin-left: 4.167%;
  border-bottom: 2px solid #eee;
  border-top: 2px solid #eee;
  grid-column-end: 24;
  grid-column-start: 2;
  -ms-grid-row-span: 5;
  grid-row-end: 2;
  grid-row-start: 1;
  margin-bottom: 1vw;
}

.expand-row {
  display: flex; 
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 5vw;
}

.expand-grid-text {
  margin-left: 20px;
  font-size: 2.5vw;
  font-weight: 400;
  font-variant: all-small-caps;
}

.expand-grid-arrow {
  font-size: 3vw;
  font-weight: 400;
  margin-right: 20px;
  transition: transform 0.5s;
}

.grid10 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-columns: 100%;
  grid-template-rows: 15vw;
  transition: height 0.5s;
  overflow: hidden;
  height: 20vw;
  margin-left: 3vw;
  margin-right: 3vw;
}


.grid10-1 {
  grid-column-end: 2;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-row-start: 1;
}

.expand-text-area {
  resize: none;
  font-family: 'Source Sans Pro';
  min-height: 2vw;
  width: 100%;
  white-space: break-spaces;
  overflow-y: scroll;
  padding: 2vw;
  /* height: auto; */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.expand-text-area::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.expand-text-area {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}